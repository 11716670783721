.date-picker {
  width: 100%;
  max-width: 450px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #c1c1c1;
  z-index: 999;

  @media (max-width: 500px) {
    max-width: 250px;
    width: 100%;
    margin-top: 8px;
  }
}
